<template>
  <div class="home page-wrapper">
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards row-deck mb-3">
          <div class="col-md-6">
            <div class="card">
              <div class="card-stamp">
                <div class="card-stamp-icon bg-green">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                </div>
              </div>
              <div class="card-body">
                <h3 class="card-title">
                  Your updated information has been saved
                </h3>
                <p>
                  {{ $auth.user.contact.firstName }}, thanks for updating and
                  verifying {{ company.companyName }}'s information
                  <span v-if="site">
                    in the
                    <strong> {{ site.sitename }}</strong
                    >.
                  </span>
                  <span v-else>.</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-stamp">
                <div class="card-stamp-icon bg-blue">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-trending-up"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline points="3 17 9 11 13 15 21 7"></polyline>
                    <polyline points="14 7 21 7 21 14"></polyline>
                  </svg>
                </div>
              </div>
              <div class="card-body d-flex flex-column justify-content-between">
                <p>
                  Keeping your profile updated helps buyers discover your brand
                  during the purchasing process.
                </p>
                <a
                  :href="profileUrl"
                  class="btn btn-outline-primary"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-external-link"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                    ></path>
                    <line x1="10" y1="14" x2="20" y2="4"></line>
                    <polyline points="15 4 20 4 20 9"></polyline>
                  </svg>

                  View Your Company's Updated Profile
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cards row-deck mb-3">
          <div class="col-md-6">
            <div class="card">
              <div class="card-stamp">
                <div class="card-stamp-icon bg-yellow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-bulb"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"
                    ></path>
                    <path
                      d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"
                    ></path>
                    <line x1="9.7" y1="17" x2="14.3" y2="17"></line>
                  </svg>
                </div>
              </div>
              <div class="card-body">
                <h3 class="card-title">Learn more about your free tools</h3>
                <p>
                  Your free listing on a
                  <a target="_blank" href="https://www.mediabrains.com/">
                    MediaBrains
                  </a>
                  directory includes access to the advertiser center. We've
                  signed you in to your account. Have a look around.
                </p>

                <ul>
                  <li>
                    <a target="_blank" href="/profile">
                      Update company info &raquo;
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="/products">
                      Add products to your listing &raquo;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-stamp">
                <div class="card-stamp-icon bg-red">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-calendar-event"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                    <line x1="16" y1="3" x2="16" y2="7"></line>
                    <line x1="8" y1="3" x2="8" y2="7"></line>
                    <line x1="4" y1="11" x2="20" y2="11"></line>
                    <rect x="8" y="15" width="2" height="2"></rect>
                  </svg>
                </div>
              </div>
              <div class="card-body d-flex flex-column">
                <h3 class="card-title">
                  Have any questions? Need a tour guide?
                </h3>

                <p>
                  Schedule a quick walkthrough with your account manager. We'll
                  show you around the advertiser center, and give you a sneak
                  peek at what benefits you could unlock with a featured
                  listing.
                </p>

                <a
                  :href="repCalendar"
                  class="btn btn-outline-primary"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-calendar-event"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                    <line x1="16" y1="3" x2="16" y2="7"></line>
                    <line x1="8" y1="3" x2="8" y2="7"></line>
                    <line x1="4" y1="11" x2="20" y2="11"></line>
                    <rect x="8" y="15" width="2" height="2"></rect>
                  </svg>

                  Schedule a demo
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cards row-deck mb-3">
          <div class="col-md-12">
            <div class="card">
              <div class="row row-0">
                <div
                  class="col-sm-4 order-sm-last p-3 d-flex align-items-center"
                >
                  <img
                    class="rounded mx-auto d-block shadow-sm custom-class"
                    src="https://uploads-ssl.webflow.com/614a6834c3407724d9620181/62048001d620aa383c8e259d_firefox_osJOoub4G1.png"
                    alt="The Visitor Insight Report shows you insights into companies actively browsing your listing."
                  />
                </div>
                <div class="col-sm-8">
                  <div
                    class="card-body d-flex flex-column justify-content-between"
                  >
                    <h3 class="card-title">
                      A full suite of lead-generating tools
                    </h3>

                    <p class="">
                      Maximize your exposure to in-market buyers by leveraging
                      the tools offered in the MediaBrains advertiser center.
                    </p>

                    <ul class="mb-2">
                      <li>
                        <a target="_blank" href="/profile">
                          Keep your listing information up to date &raquo;
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/products">
                          Feature your products and services on your profile
                          &raquo;
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="/reports/audience-report">
                          Identify current consumer trends in your market
                          &raquo;
                        </a>
                      </li>
                      <locked>
                        Unlock informative monthly stats about how buyers are
                        engaging with your profile
                      </locked>
                      <locked>
                        Receive sales-ready leads directly to your inbox
                      </locked>
                      <locked>
                        Feature your company logo, website URL, and social media
                        links on your company's profile
                      </locked>
                      <locked>
                        Appear in additional categories for all markets you
                        serve
                      </locked>
                      <locked>
                        Serve unlimited lead generating company content and
                        press releases directly to your target audience
                      </locked>
                      <li>
                        <upgrade-link :site="site"></upgrade-link>
                      </li>
                    </ul>

                    <a
                      target="blank"
                      class="btn btn-primary"
                      href="/"
                      style="max-width: 280px"
                    >
                      View your company dashboard &raquo;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cards row-deck mb-3">
          <div class="col-md-12">
            <div class="card">
              <div class="row row-0">
                <div
                  class="col-sm-4 order-sm-last p-3 d-flex align-items-center"
                >
                  <img
                    class="rounded mx-auto d-block shadow-sm custom-class"
                    src="https://uploads-ssl.webflow.com/614a6834c3407724d9620181/62048002a418c66c775912d3_firefox_wXilYnhkwn.png"
                    alt="The Visitor Insight Report shows you insights into companies actively browsing your listing."
                  />
                </div>
                <div class="col-sm-8">
                  <div
                    class="card-body d-flex flex-column justify-content-between"
                  >
                    <h3 class="card-title">
                      Get insights into which customers are in-market
                    </h3>

                    <p class="">
                      With the MediaBrain's Visitor Insights Report you'll learn
                      who's actively in-market for the products and services you
                      offer and get informative monthly stats about how buyers
                      are engaging with your profile.
                    </p>

                    <ul>
                      <li>
                        Unlock company domain, company size and industry for
                        customers visiting your profile
                      </li>
                      <li>See which categories prospects are viewing</li>
                      <li>Download full visitor activity reports</li>
                      <li>
                        Identify topics your visitors are actively researching
                      </li>
                    </ul>

                    <a
                      target="blank"
                      class="btn btn-primary"
                      href="/reports/visitor-insight-report"
                      style="max-width: 280px"
                    >
                      View your visitor insights &raquo;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cards row-deck">
          <div class="col-md-12">
            <div class="card">
              <div class="row row-0">
                <div
                  class="col-sm-4 order-sm-last p-3 d-flex align-items-center"
                >
                  <img
                    class="rounded mx-auto d-block shadow-sm custom-class scrolling-image"
                    src="https://uploads-ssl.webflow.com/614a6834c3407724d9620181/62048711b3349c88e973d766_3EQ37kL9om.png"
                    alt="The Audience Report shows who is visiting a directory site."
                  />
                </div>
                <div class="col-sm-8">
                  <div
                    class="card-body d-flex flex-column justify-content-between"
                  >
                    <h3 class="card-title">
                      Easily identify your target market
                    </h3>

                    <p class="">
                      Our in-market audience insights can help you make
                      data-driven marketing decisions that drive high quality
                      leads directly to your inbox. Make sure your advertising
                      spend is reaching your target audience, exclusively with
                      MediaBrains directories.
                    </p>

                    <ul>
                      <li>
                        Identify the job level and function of buyers active in
                        the directory
                      </li>
                      <li>Gain insights into company size and industry</li>
                      <li>
                        Find trending topics across visitors to this directory
                      </li>
                    </ul>

                    <a
                      target="blank"
                      class="btn btn-primary"
                      href="/reports/audience-report"
                      style="max-width: 280px"
                    >
                      View audience reports &raquo;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// Example: Hide starting at `min-width: 0`, and then show at the `sm` breakpoint
.custom-class {
  max-height: 180px;
}
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .custom-class {
    max-height: 180px;
  }
}
</style>

<script>
import Locked from "../../components/Verify/Locked.vue";
import UpgradeLink from "../../components/Verify/UpgradeLink.vue";
import SiteService from "../../services/SiteService";
import TrackingService from "../../services/TrackingService";

export default {
  components: { Locked, UpgradeLink },
  name: "verify.thank-you",
  data() {
    let calendars = [
      "https://calendly.com/michael-coy-mediabrains/30-minute-conversation",
      "https://calendly.com/judith-gaa/meeting",
    ];
    return {
      name: "verify",
      company: {},
      repCalendar: calendars[Math.floor(calendars.length * Math.random())],
      site: null,
    };
  },
  computed: {
    profileUrl() {
      if (!this.site) {
        return "#";
      }
      return `https://${this.site.directoryurl}/company/${
        this.company.companyId
      }/${this.slug(this.company.companyName)}`;
    },
  },
  methods: {
    slug(name) {
      name = name
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, ""); // trim - from end of text
      return encodeURIComponent(name);
    },
  },
  async mounted() {
    this.company = this.$auth.user.companies[0];

    if (this.$auth.user.companies.length > 0 && this.$route.query.company) {
      try {
        let companyHint = parseInt(this.$route.query.company);
        // update based on hint
        let companyMatch = this.$auth.user.companies.filter((c) => {
          return c.companyId == companyHint;
        });

        if (companyMatch.length > 0) {
          this.company = companyMatch[0];
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (this.$route.query.site) {
      this.site = await SiteService.getSite(this.$route.query.site);
    } else {
      this.site = await SiteService.getSite(this.company.sites[0].siteID);
    }

    try {
      // eslint-disable-next-line no-undef
      clarity("set", "campaign", "verify-2022");
    } catch {
      console.log("clarity error");
    }

    TrackingService.track("viewed verify campaign thank you page");
  },
};
</script>
